import { useState, useEffect, useRef } from "react";
import "./question.scss";
import MusicIcon from "../../assets/icons/music.svg";
import { ApiGet, ApiPost } from "../../Helpers/Api/ApiData";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import * as userUtil from "../../utils/user.util";
import {useLocation} from "react-router-dom";
import Loader from 'react-loader-spinner'

export default function Question() {
  let userInfo = userUtil.getUserInfo();
  const { id } = useParams();
  const search = useLocation().search;
  const cid = new URLSearchParams(search).get('cid');
  const [questionData, setQuestionData] = useState();
  const history = useHistory();
  const [questionKEY, setQuestionKEY] = useState(0);
  const[Loading,setLoading]=useState(false);
  const ABC = ["Option 1", "Option 2", "Option 3", "Option 4"];
  useEffect(() => {
    getAllQuestion();
    
  }, []);

  const getAllQuestion = async () => {
    setLoading(true)
    await ApiGet(`question/get-all-question?course_id=${id}`)
      .then((res) => {
        setLoading(false)
        if (res?.data?.result === 0) {
          res?.data?.payload?.question?.map((e, index) => {
            e.Answer = [];
            return e;
          });

          setQuestionData(res?.data?.payload?.question);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message, { theme: "colored" });
      });
  };

  const handleQuestion = (data, record, index) => {
    if (record.type === "mcq") {
      let index1 = record.Answer.findIndex((e) => e === index + 1);
      if (index1 === -1) {
        record.Answer = [index + 1];
      } else {
        record.Answer.splice(index1, 1);
      }
    } else if (record.type === "checkbox") {
      let index1 = record.Answer.findIndex((e) => e === index + 1);
      if (index1 === -1) {
        record.Answer.push(index + 1);
      } else {
        record.Answer.splice(index1, 1);
      }
    }
    let index2 = questionData?.findIndex((e) => e._id === record._id);
    if (index2 != -1) {
      questionData[index2] = record;
    }
    setQuestionData(questionData);
  };

  const addQuestionSet = () => {
    let checkData = [];
    questionData?.map((e) => {
      e.option.map((o) => {
        delete o._id;
        return o;
      });
      let a = {
        Question: e?.qname,
        Option: e.option,
        Answer: e.Answer,
        type: e.type,
      };
      checkData.push(a);
    });
    let ans1 = [];
    questionData?.map((ans) => {
      if (!ans.Answer.length) {
        ans1.push(ans.Answer);
      } else {
      }
    });

    const datas = {
      uid: userInfo?._id,
      course_id: cid,
      aid: userInfo?.aid,
      isExamDone: true,
      qset_id:id,
      ListofQA: checkData,
    };
    console.log("datasss", datas);
    ApiPost(`response/addResponse`, datas)
      .then((res) => {
        toast.success(res?.data?.message);
        history.push(`/questionSet/${cid}`);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  return (
    <div>
      <ToastContainer />
      <div className="courses-section">
        <div className="page-titles">
          <h1>Fragensatz|{localStorage.getItem("question set name")}</h1>
          <button className="btns" onClick={() => history.goBack()}>
          Zurück
          </button>
        </div>
        <Loader
            className="Loading"
            type="Puff"
            color="#5B5B5E"
            height={35}
            width={35}
            visible={Loading}
          />
        <div className="gridss">
          {questionData?.map((data, key) => (
            <div className="grid-items">
              <div className="flex">
                <div className="mr-3" key={key}>
                  {key + 1}
                  {")"}
                </div>

                {data?.qname}
              </div>

              <div className="">
                {data?.option.map((record, i) => (
                  <>
                    {data.type === "mcq" ? (
                      <>
                        <div className="options" key={i}>
                          <input
                            type="radio"
                            name={key}
                            id="radio"
                            defaultChecked={data.istrue}
                            onChange={(e) => handleQuestion(e, data, i)}
                          />
                          <span className="pl-2">
                            {" "}
                            {/* Option  {i + 1}= */}
                            {record?.name}
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="options" key={i}>
                          <input
                            type="checkbox"
                            id={record?.name}
                            onChange={(e) => handleQuestion(e, data, i)}
                          />

                          <span className="pl-2">
                            {" "}
                            {/* Option  {i + 1} = */}
                            {record?.name}
                          </span>
                        </div>
                      </>
                    )}
                  </>
                ))}
              </div>
            </div>
          ))}
        </div>

        {
          questionData?.length > 0 && <div className="">
           <button className="btns" onClick={() => addQuestionSet(questionData)}>
            Einreichen
          </button>
          </div>
        }
        {
          !questionData?.length &&Loading===false&& <div className="text-center">
            <div>Kein Fragensatz gefunden</div>
          </div>
        }

      </div>
    </div>
  );
}

import { useEffect, useState } from "react";
import "./courseLessons.scss";
import "../../../src/routes/home/customerSupport//customer-support.scss";
import * as userUtil from "../../utils/user.util";
import { ApiGet } from "../../Helpers/Api/ApiData";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

export default function CourseLessons() {
  let userInfo = userUtil.getUserInfo();
  const history = useHistory();
  const { id } = useParams();
  const [lessonData, setLessonData] = useState();

  useEffect(() => {
    localStorage.setItem("CourseName", "");
    getAllLessons();
  }, []);

  const getAllLessons = async () => {
    await ApiGet(`lesson/get-lesson?cid=${id}&isActive=true`)
      .then((res) => {
        console.log("get lesson", res?.data?.payload?.lesson);
        if (res?.data?.result === 0) {
          setLessonData(res?.data?.payload?.lesson);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message, { theme: "colored" });
      });
  };

  return (
    <div>
      <div className="courses-section">
        <div className="page-title">
          <h1>All Lessons</h1>
        </div>

        <div className="grid">
          {lessonData?.map((item, index) => {
            return (
              <div className="grid-items">
                <img className="video-player" src={item?.image}></img>
                <p>{item?.description}</p>
                <button
                  onClick={() => {
                    localStorage.setItem("CourseName", item?.name);
                    history.push(`/Courses-details/${item?._id}`);
                  }}
                >
                  Anfang
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

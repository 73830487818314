import React, { useEffect, useState } from "react";
import './calender.scss';
import * as userUtil from "../../utils/user.util";
import { toast } from "react-toastify";

import {
  Inject,
  ScheduleComponent,
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda
  // EventSettingsModel
} from "@syncfusion/ej2-react-schedule";
import { ApiGet, ApiPost, ApiPut } from "../../Helpers/Api/ApiData";

export default function Calendar() {
  const data = [
    {
      Id: 1,
      Subject: "Meeting - 1",
      EndTime: new Date(),
      StartTime: new Date()
    }
  ];
  const [UserData, setUserData] = useState({});
  const [errors, setErrors] = useState({});
  const [accordion, setAccordion] = useState(false);
  const [loader, setLoader] = useState(false);
  const userInfo = userUtil.getUserInfo();
  useEffect(() => {
    getUsers()
  }, [])
  useEffect(() => {
    console.log("aaaaaaa",UserData)
  }, [UserData])


  const handleAddProfileDetails = (e) => {
    const { name, value } = e.target;
    setUserData({ ...UserData, [name]: value });
    setErrors({...errors,fname:""})


  };
  const validationForm = () => {
    let errors = {};
    let formValied = true;
    let link = new RegExp("^(https?:\\/\\/)?((outlook\\.)?live\\.com)");
    if (!UserData?.fname?.match(link)) {
      formValied = false;
      errors["fname"] = "Bitte Wert eingeben.";
    }
    setErrors(errors);
    return formValied;
  };

  const getUsers = async () => {
    let userInfo = userUtil.getUserInfo();
    // setLoading(true);
    await ApiGet(`calender/get-all-calender?uid=${userInfo?._id}`)
      .then((res) => {
        // setLoading(false);
        console.log("get user", res?.data?.payload?.admin);
        setUserData(res?.data?.payload?.calender[0]);

      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const updateUser = async (e) => {
    if (validationForm()) {
      setLoader(true);

      let Data = {

        uid: userInfo?._id,
        email: userInfo?.email,
        iframe: UserData?.fname

      }
      await ApiPost(`calender/add-calender`, Data) //api for update profile pic
        .then((res) => {
          console.log("update pic res", res);
          if (res?.data?.result === 0) {

            setLoader(false);
            getUsers()
            setErrors({})
            toast.success(res?.data?.message);
            setUserData({})
          }
        })
        .catch((err) => {
          console.log("err", err);
          setErrors({})
          setUserData({})
          getUsers()
          toast.error(err);
        });

    }
  };

  let Quots = '"Kalender"';
  let Quots2 = '"Freigegebene Kalender"';
  let Quot3 = '"Kalender veröffentlichen"';


  return (
    <>
      <div className="courses-section">
        <div className="page-titles">
          <h1>Kalender</h1>

          {/* <p>fined button steps</p> */}

          <div className="accordion">
            <div className="accordion-item">
              <div className="accordion-header" onClick={() => setAccordion(!accordion)}>
                <h4> Befolgen Sie die folgenden Schritte, um einen Kalender hinzuzufügen. </h4>
                <div className="accordion-iocn">
                  {accordion === true ? <i class="fas fa-minus"></i> :   <i class="fas fa-plus"></i>}
                </div>
              </div>
              {accordion && (
                <div className="accordion-body">
                  <ol>
                  <li>                 
                    {
                      " Wählen Sie in der Kalenderansicht Einstellungen > Alle Outlook Einstellungen anzeigen aus."
                    }
                  </li>
                  <li>
                    {" Wählen Sie " + Quots + " > " + Quots2 + " aus."}
                  </li>
                  <li>
                    {" Wählen Sie unter " +
                      Quot3 +
                      "aus, welchen Kalender Sie veröffentlichen möchten."}
                  </li>
                  <li> Wählen Sie Veröffentlichen aus.</li>
                  <li>
                     Wählen Sie HTML-Link aus und kopieren Sie diesen.
                  </li>
                  <li>
                    Fügen Sie diesen nun hier in der Akademie im HTML
                    Feld ein
                  </li>
                  <div>ODER</div>
                  <div>
                    Sie können zum Abschnitt "Direct Publish Calender" gehen{" "}
                  </div>
                  </ol>
                  <a
                    className="link-wrap"
                    target="_blank"
                    href="https://outlook.live.com/calendar/0/options/calendar/SharedCalendars/sharedCalendars"
                  >
                    {" "}
                    Klicken Sie hier
                  </a>

                
                </div>
              )}
            </div>
          </div>





          {/* OR you can find by given link
         <a>https://outlook.live.com/calendar/0/options/calendar/SharedCalendars/sharedCalendars</a> */}
          <div className="input">
            <label>HTML-Code eingeben</label>
            <input
              type="text"
              name="fname"
              value={UserData?.fname}
              onChange={(e) => handleAddProfileDetails(e)}
            />
            <span
              style={{
                color: "red",
                fontSize: "18px",
              }}
            >
              {errors["fname"]}
            </span>
          </div>
          <br />
          <br />
          <br />
          <br />

          <div className="btnWrapper">
            <button onClick={(e) => updateUser(e)} className="update-btn">
              <>
                <div>Hinzufügen</div>
              </>
            </button>
          </div>
          <div>
            {UserData?.iframe ?  <iframe src={UserData?.iframe} style={{ border: "0px" }} width="100%" height="700px" />: "Kein Kalender verfügbar"}
          </div>
        </div>
        {/* <ScheduleComponent
          currentView="Month"
          eventSettings={{
            allowAdding: false,
            allowDeleting:false,
            allowEditing:false,
            dataSource: data,
            fields: {
              id: "Id",
              subject: { name: "Subject" },
              startTime: { name: "StartTime" },
              endTime: { name: "EndTime" }
            }
          }}
        >
          <Inject services={[Day, Week, WorkWeek, Month, Agenda]} />
        </ScheduleComponent> */}
      </div>




    </>
  );
}

import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Sidebar from "../components/sidebar";
import Courses from "./courses";
import CoursesDetails from "./coursesDetails";
import Fortbildung from "./fortbildung";
import Home from "./home";
import Login from "./login";
import * as authUtil from "../../src/utils/auth.util";
import Profile from "./profile";
import CourseLessons from "./CourseLessons";
import Quiz from "./Quiz";
import Question from "./Question";
import Board from "./Board/Board";
import Calender from "./Calender/Calender";
import Logoutmodal from "../../src/routes/login/logoutmodal";
import Record from "./Records/records";
import QuestionSet from "./QuestionSet";
import VideoScreen from "./Videosdk";
import { JoiningScreen } from "./Videosdk/JoiningScreen";
import { MeetingDetailsScreen } from "./Videosdk/MeetingDetailsScreen";
import UserProgress from "./Progress/Progress";
import CustomerSupport from "./home/customerSupport";
import Meeting from "./Meeting/Meeting"
import Notification from '../../src/routes/login/Notification';

function RouteWrapper({ component: Component, layout: Layout, auth, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

const DefaultLayout = ({ children, match }) => (
  <>
    {authUtil.isLoggedIn() ? (
      <div className="layout-grid">
        <div className="layout-grid-items">
          <Sidebar />
        </div>
        <div className="layout-grid-items">{children}</div>
      </div>
    ) : (
      <>
        <Login />
      </>
    )}
  </>
);
const ChildLayout = ({ children, match }) => <>{children}</>;

export default function Routes() {
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <RouteWrapper
            exact={true}
            path="/"
            component={CustomerSupport}
            layout={DefaultLayout}
          />
          <RouteWrapper
            exact={true}
            path="/fortbildung"
            component={Fortbildung}
            layout={DefaultLayout}
          />
          <RouteWrapper
            exact={true}
            path="/profile"
            component={Profile}
            layout={DefaultLayout}
          />
          <RouteWrapper
            exact={true}
            path="/courses"
            component={Courses}
            layout={DefaultLayout}
          />
          <RouteWrapper
            exact={true}
            path="/progress"
            component={UserProgress}
            layout={DefaultLayout}
          />
          <RouteWrapper
            exact={true}
            path="/quiz"
            component={Quiz}
            layout={DefaultLayout}
          />
          <RouteWrapper
            exact={true}
            path="/board"
            component={Board}
            layout={DefaultLayout}
          />
          <RouteWrapper
            exact={true}
            path="/calender"
            component={Calender}
            layout={DefaultLayout}
          />
          <RouteWrapper
            exact={true}
            path="/video"
            component={VideoScreen}
            layout={DefaultLayout}
          />
          <RouteWrapper
            exact={true}
            path="/join"
            component={JoiningScreen}
            layout={DefaultLayout}
          />
          <RouteWrapper
            exact={true}
            path="/metting"
            component={MeetingDetailsScreen}
            layout={DefaultLayout}
          />
            <RouteWrapper
            exact={true}
            path="/quiz/:id"
            component={Question}
            layout={DefaultLayout}
          />
          <RouteWrapper
            exact={true}
            path="/questionSet/:id"
            component={QuestionSet}
            layout={DefaultLayout}
          />
          <RouteWrapper
            exact={true}
            path="/Courses-details/:id"
            component={CoursesDetails}
            layout={DefaultLayout}
          />
          <RouteWrapper
            exact={true}
            path="/login"
            component={Login}
            layout={ChildLayout}
          />
          <RouteWrapper
            exact={true}
            path="/all-lessons/:id"
            component={CourseLessons}
            layout={DefaultLayout}
          />
          <RouteWrapper
            exact={true}
            path="/logout"
            component={Logoutmodal}
            layout={DefaultLayout}
          />
          <RouteWrapper
            exact={true}
            path="/records"
            component={Record}
            layout={DefaultLayout}
          />
          <RouteWrapper
            exact={true}
            path="/meeting"
            component={Meeting}
            layout={DefaultLayout}
          />
           <RouteWrapper
            exact={true}
            path="/Notification"
            component={Notification}
            layout={DefaultLayout}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

import { render } from "react-dom";
import "./board.scss";
import "./board1.css";
import * as React from "react";
// import { extend } from '@syncfusion/ej2-base';
import { extend, closest, remove, addClass, L10n } from "@syncfusion/ej2-base";
import { ToastContainer, toast } from "react-toastify";
import * as dataSource from "./datasource.json";
import {
  KanbanComponent,
  ColumnsDirective,
  ColumnDirective,
} from "@syncfusion/ej2-react-kanban";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { SampleBase } from "./sample-base";
import { PropertyPane } from "./property-pane";
import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../Helpers/Api/ApiData";
import * as userUtil from "../../utils/user.util";
import Multiselect from "multiselect-react-dropdown";
import Select from "react-select";

/**
 * Kanban Overview sample
 */
export class Board extends SampleBase {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: userUtil.getUserInfo(),
      addData: {},
      data: extend([], [], true),
      getAllMenu: [],
      selectedCourseType1: [],
      errorsForAdd: {},
      allCourseTypeForUpdate1: [],
      isEdit: false,
      selectedOption: null,
    };
    L10n.load({
      "en-US": {
        kanban: {
          addTitle: "Neue Karte hinzufügen",
          editTitle: "Kartendetails bearbeiten",
          save: "Speichern",
          delete: "Löschen",
          cancel: "Abbrechen",
        },
      },
    });
  }
  handleChange = (selectedOption) => {
    this.setState({ ...this.state, selectedOption: selectedOption });
  };

  kanbanObj;
  addClick() {
    console.log("kanbanObj", this.kanbanObj.kanbanData);
    const cardIds = this.kanbanObj.kanbanData?.length
      ? this.kanbanObj.kanbanData.map((obj) =>
          parseInt(obj.ID.replace("Task ", ""), 10)
        )
      : [0];
    console.log("cardIds", cardIds);
    const cardCount = Math.max.apply(Math, cardIds) + 1;
    console.log("cardCount", cardCount);
    const cardDetails = {
      ID: "Task " + cardCount,
      Status: "Open",
      Priority: "Normal",
      Assignee: "Andrew Fuller",
      Estimate: 0,
      Tags: "",
      Summary: "",
    };
    this.kanbanObj.openDialog("Add", cardDetails);
  }
  tempUserData = [];
  getAllMenuData = () => {
    ApiGet(`admin/getUserListForBoard?aid=${this.state.userInfo?.aid}`).then(
      (res) => {
        this.setState({
          ...this.state,
          getAllMenu: res?.data?.payload?.findUsers,
        });
        res?.data?.payload?.findUsers.length &&
          res?.data?.payload?.findUsers.filter((item) =>{
            if(item.position=="User"){
              this.tempUserData.push({ value: item._id, label: item.fname })
            }
          }
          );
      }
    );
  };
  componentDidMount() {
    let userInfo1 = userUtil.getUserInfo();
    ApiGet(`board/get-all-board?uid=${this.state.userInfo?._id}`)
      .then((res) => {
        console.log("get courses", res?.data?.payload?.board);
        this.setState({
          ...this.state,
          data: extend([], res?.data?.payload?.board, true),
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
    if (this.state.userInfo?.tlid && this.state.userInfo?.tlid !== null) {
      ApiGet(
        `boardName/get-all-boardName?aid=${this.state.userInfo?.aid}&tlid=${this.state.userInfo?.tlid}`
      )
        .then((res) => {
          console.log("get ", res?.data?.payload?.boardName);
          this.setState({
            ...this.state,
            board: res?.data?.payload?.boardName,
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      ApiGet(`boardName/get-all-boardName?aid=${this.state.userInfo?.aid}`)
        .then((res) => {
          console.log("get ", res?.data?.payload?.boardName);
          this.setState({
            ...this.state,
            board: res?.data?.payload?.boardName,
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    }

    this.getAllMenuData();
  }
  dialogOpen(args) {
    if (args.requestType == "Add") {
      this.setState({ ...this.state, isEdit: false });
    }
    if (args.requestType == "Edit") {
      this.setState({ ...this.state, isEdit: true });
    }
  }

  dialogTemplate(props) {
    return (
      <KanbanDialogFormTemplate
        {...props}
        statusData={
          this.state.board && this.state.board?.length
            ? this.state.board.map((record) => {
                return record?.name;
              })
            : []
        }
        userData={this.tempUserData}
        // userData={
        //   this.state.getAllMenu && this.state.getAllMenu?.length
        //     ? this.state.getAllMenu.map((record) => {
        //         return record;
        //       })
        //     : []
        // }
        onChangeData={(data) => {
          delete data.onChangeData;
          this.setState({ ...this.state, addData: data });
        }}
        isEdit={this.state.isEdit}
        selectedOption={this.state.selectedOption}
        handleChange={this.handleChange}
      />
    );
  }

  onActionBegin(args) {
    if (args?.addedRecords.length > 0) {
      const datas = {
        uid: this.state?.selectedOption?.map((item) => item.value),
        ID: args?.addedRecords[0]?.ID,
        Status: args?.addedRecords[0]?.Status,
        Priority: args?.addedRecords[0]?.Priority,
        Summary: args?.addedRecords[0]?.Summary,
        addedBy: this.state.userInfo?._id,
      };
      if(datas.uid=== undefined) return toast.error("Bitte Benutzer auswählen")
      if (
        args?.addedRecords[0]?.Summary?.length > 0 &&
        this.state.selectedOption?.length > 0
      ) {
        ApiPost(`board/add-board`, datas)
          .then((res) => {
            toast.success(res?.data?.message);
            ApiGet(`board/get-all-board?uid=${this.state.userInfo?._id}`)
              .then((res) => {
                this.setState({
                  ...this.state,
                  data: extend([], res?.data?.payload?.board, true),
                });
              })
              .catch((err) => {
                console.log("err", err);
              });
          })
          .catch((err) => {
            toast.error(err);
          });
      }
    }
    if (args?.changedRecords.length > 0) {
      const datas = {
        // uid: args?.changedRecords?.[0]?.userData,
        ID: args?.changedRecords?.[0]?.ID,
        Status: args?.changedRecords?.[0]?.Status,
        Priority: args?.changedRecords?.[0]?.Priority,
        Summary: args?.changedRecords?.[0]?.Summary,
        userData: this.state.userInfo?._id,
      };
      ApiPut(`board/update-board/${args?.changedRecords?.[0]?._id}`, datas)
        .then((res) => {
          toast.success(res?.data?.message);
          ApiGet(`board/get-all-board?uid=${this.state.userInfo?._id}`)
            .then((res) => {
              this.setState({
                ...this.state,
                data: extend([], res?.data?.payload?.board, true),
              });
            })
            .catch((err) => {
              console.log("err", err);
            });
        })
        .catch((err) => {
          toast.error(err);
        });
    }
    if (args?.deletedRecords.length > 0) {
      ApiDelete(`board/delete-board/${args?.deletedRecords?.[0]?._id}`)
        .then((res) => {
          toast.success(res?.data?.message);
          ApiGet(`board/get-all-board?uid=${this.state.userInfo?._id}`)
            .then((res) => {
              this.setState({
                ...this.state,
                data: extend([], res?.data?.payload?.board, true),
              });
            })
            .catch((err) => {
              console.log("err", err);
            });
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  }
  render() {
    return (
      <>
        <ToastContainer />
        <div className="schedule-control-section">
          <div className="courses-section">
            <div className="page-title display">
              <div>
                <h1>To Do</h1>
                {/* Board  */}
              </div>
              <div className="col-lg-3 property-section">
                {/* <PropertyPane title='Properties'> */}
                <table id="property" title="Neuen Eintrag hinzufügen">
                  <tr>
                    <td>
                      <ButtonComponent
                        id="addNew"
                        className="btns"
                        onClick={this.addClick.bind(this)}
                      >
                        Neuen Eintrag hinzufügen
                      </ButtonComponent>
                      {/* ADD NEW CARD */}
                    </td>
                  </tr>
                </table>
                {/* </PropertyPane> */}
              </div>
            </div>
            <div className="kanban-control-section">
              <div className="col-lg-9 control-section">
                <div className="control-wrapper">
                  <div className="kanban-section">
                    <KanbanComponent
                      id="kanban"
                      ref={(kanban) => {
                        this.kanbanObj = kanban;
                      }}
                      dialogOpen={this.dialogOpen.bind(this)}
                      keyField="Status"
                      dataSource={this.state.data}
                      cardSettings={{
                        contentField: "Summary",
                        headerField: "ID",
                      }}
                      actionComplete={this.onActionBegin.bind(this)}
                      dialogSettings={{
                        template: this.dialogTemplate.bind(this),
                      }}
                    >
                      <ColumnsDirective>
                        {this.state?.board && this.state?.board?.length
                          ? this.state?.board.map((record, index) => {
                              return (
                                <ColumnDirective
                                  key={index}
                                  headerText={record?.name}
                                  keyField={record?.name}
                                />
                              );
                            })
                          : null}
                        {/* <ColumnDirective headerText="To Do" keyField="Open" />
                        <ColumnDirective headerText="In Progress" keyField="InProgress" />
                        <ColumnDirective headerText="Testing" keyField="Testing" />
                        <ColumnDirective headerText="Done" keyField="Close" /> */}
                      </ColumnsDirective>
                    </KanbanComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export class KanbanDialogFormTemplate extends React.Component {
  // assigneeData = [
  //   'Nancy Davloio', 'Andrew Fuller', 'Janet Leverling',
  //   'Steven walker', 'Robert King', 'Margaret hamilt', 'Michael Suyama'
  // ];
  selectedOption = this.props.selectedOption;
  handleChange = this.props.handleChange;
  statusData = this.props.statusData;
  userData = this.props.userData;
  priorityData = ["Niedrig", "Mittel", "Hoch"];
  // tagsHtmlAttributes = { name: "Tags" };
  constructor(props) {
    super(props);
    this.state = extend({}, {}, props, true);
  }

  onChange(args) {
    let key = args.target.name;
    let value = args.target.value;
    this.setState({ [key]: value });
    this.props.onChangeData(this.state);
  }
  render() {
    let data = this.state;
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td align="center">Titel</td>
              <td>
                <div className="e-float-input e-control-wrapper">
                  <input
                    id="ID"
                    name="ID"
                    type="text"
                    className="e-field"
                    value={data.ID}
                    onChange={this.onChange.bind(this)}

                    // disabled
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="e-label" align="center">
                Status
              </td>
              <td>
                <DropDownListComponent
                  id="Status"
                  name="Status"
                  dataSource={this.statusData}
                  className="e-field"
                  placeholder="Status"
                  value={data.Status}
                ></DropDownListComponent>
              </td>
            </tr>
            {/* <tr>
            <td className="e-label">Assignee</td>
            <td>
              <DropDownListComponent id='Assignee' name="Assignee" className="e-field" dataSource={this.assigneeData} placeholder='Assignee' value={data.Assignee}></DropDownListComponent>
            </td>
          </tr> */}
            <tr>
              <td className="e-label" align="center">
                Priorität
              </td>
              <td>
                <DropDownListComponent
                  type="text"
                  name="Priority"
                  id="Priority"
                  popupHeight="300px"
                  className="e-field"
                  value={data.Priority}
                  dataSource={this.priorityData}
                  placeholder="Priorität"
                ></DropDownListComponent>
              </td>
            </tr>
            <tr>
              <td className="e-label" align="center">
                Beschreibung
              </td>
              <td>
                <div className="e-float-input e-control-wrapper">
                  <textarea
                    name="Summary"
                    className="e-field"
                    value={data.Summary}
                    onChange={this.onChange.bind(this)}
                  ></textarea>
                </div>
              </td>
            </tr>
            {/* {console.log("this", this.props)}
            {!this.props.isEdit && (
              <tr>
                <td className="e-label">Teilnehmer</td>
                <td>
                  <DropDownListComponent
                    type="text"
                    name="userData"
                    value={data.userData}
                    id="Users"
                    popupHeight="300px"
                    className="e-field"
                    dataSource={this.userData}
                    placeholder="Teilnehmer"
                  ></DropDownListComponent>
                </td>
              </tr>
            )} */}

            <tr>
              <td className="e-label" align="center">
                Teilnehmer
              </td>
              <td>
                {console.log("otetdf", this.userData)}
                <Select
                  // defaultValue={this.selectedOption}
                  onChange={this.handleChange}
                  isMulti
                  options={this.userData}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default Board;

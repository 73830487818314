import { useEffect, useState } from "react";
import "./questionset.scss";
import "../../../src/routes/home/customerSupport//customer-support.scss";
import * as userUtil from "../../utils/user.util";
import { ApiGet } from "../../Helpers/Api/ApiData";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useHistory } from "react-router-dom";
import Progress from "react-progressbar";
import { useParams } from "react-router-dom";

export default function QuestionSet() {
  let userInfo = userUtil.getUserInfo();
  const history = useHistory();
  const [courseData, setCourseData] = useState();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    localStorage.setItem("CourseName", null);

    getAllCourses();
  }, []);

  const getAllCourses = async () => {
    setLoading(true);
    await ApiGet(`questionSet/getQuestionSet?cid=${id}&uid=${userInfo?._id}`)
      .then((res) => {
        setLoading(false);
        console.log("get courses", res?.data?.payload?.courses);
        setCourseData(res?.data?.payload?.Questionset);
        console.log("aaa", res?.data?.payload?.Questionset);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  return (
    <div>
      <div className="courses-section">
        <div className="page-title1">
          <h1>{localStorage.getItem("set data-------->")} | Fragensatz</h1>
          <br />
          <button className="btns" onClick={() => history.goBack()}>
          Zurück
          </button>
        </div>
        <Loader
          className="Loading"
          type="Puff"
          color="#5B5B5E"
          height={35}
          width={35}
          visible={loading}
        />

        <div className="grids">
          {courseData?.map((item, index) => {
  
            return (
              <>
                <div
                  className="grid-itemss"
                  onClick={() => {!item?.response[0]?.Score.toString() &&
                    history.push(`/quiz/${item?._id}?cid=${id}`);
                    localStorage.setItem("question set name", item?.name);
                  }}
                >
                  <div className="spce">
                    <div>
                      {" "}
                      <h1 className="lektion">{item?.name}</h1>
                    </div>
                    <div className="count">
                      {item?.response[0]?.Score.toString()
                        ? "Punktzahl = " + item?.response[0]?.Score +"/" +item?.response[0]?.Total 
                        : "Nicht vollständig"}
                    </div>
                    <div style={{color: "smoky"}}>
                      {item?.response[0]?.Score.toString()
                        ? "*Test bereits gegeben"
                        : null}
                    </div>
                  </div>
                  <div className="mb-5">
                    <Progress
                      completed={
                        item?.response[0]?.percentage
                          ? item?.response[0]?.percentage
                          : 0
                      }
                    />
                  </div>
                </div>
              </>
            );
          })}
          {!courseData?.length && loading === false && (
            <div className="text-center">
              <div>Kein Fragensatz gefunden</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

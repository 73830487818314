import { useEffect, useState } from "react";
import "./quiz.scss";
import "../../../src/routes/home/customerSupport//customer-support.scss";
import * as userUtil from "../../utils/user.util";
import { ApiGet } from "../../Helpers/Api/ApiData";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useHistory } from "react-router-dom";
import Progress from "react-progressbar";

export default function Quiz() {
  let userInfo = userUtil.getUserInfo();
  const history = useHistory();
  const [courseData, setCourseData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    localStorage.setItem("CourseName",null);
    
    getAllCourses();
  }, []);

  const getAllCourses = async () => {
    setLoading(true);
    await ApiGet(`course/get-all-course-done?uid=${userInfo?._id}`)
      .then((res) => {
        setLoading(false);
        console.log("get courses", res?.data?.payload?.courseDone);
        setCourseData(res?.data?.payload?.courseDone);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };
  return (
    <div>
      <div className="courses-section">
        <div className="page-title2">
          <h1>Quiz</h1>
        </div>

        <div className="grids">
          <Loader
            className="Loading"
            type="Puff"
            color="#5B5B5E"
            height={35}
            width={35}
            visible={loading}
          />
          {courseData?.map((item, index) => {
            return (
              <>
                <div
                  className="grid-itemss"
                  onClick={() => {history.push(`/questionSet/${item?._id}`);
                                  localStorage.setItem("set data-------->",item?.name)
                            }}
                >
                  <div className="spce">
                    <div>
                      <h1 className="lektion">{item?.name}</h1>
                    </div>
                    {/* <div className="count">{item?.response[0]?.Score ? item?.response[0]?.Score : 0}</div> */}
                  </div>
                  {/* <div className="mb-5">
                  <Progress completed={item?.response[0]?.percentage ? item?.response[0]?.percentage : 0} />
                </div> */}
                </div>
              </>
            );
          })}
          {!courseData?.length && loading === false ? (
            <div className="text-center">
              <div> Kein Quiz vorhanden - keep calm and study</div>
              {/* No Quiz Found */}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

import React from 'react'
import './fortbildung.scss';
import WatchIcon from '../../assets/icons/watch.svg';
import AddIcon from '../../assets/icons/add.svg';
export default function Fortbildung() {
  return (
    <div>
        <div className='fortbildung-section'>
            <div className='fortbildung-box'>
                <h1>Einen neuen Kurs erstellen</h1>
                <div className='input'>
                    <label>Name</label>
                    <input type="text" />
                </div>
                <div className='input'>
                    <label>Kategorie</label>
                    <input type="text" />
                </div>
                <div className='input'>
                    <label>Bezeichnung</label>
                    <textarea></textarea>
                </div>
                <div className='lektion-alignment'>
                    <div className='sub-title'>
                        <h2>Lektion 1</h2>
                    </div>
                    <button>
                        <img src={WatchIcon} alt="WatchIcon"/>
                        <span>Datei hochladen</span>
                    </button>
                    <div className='input'>
                        <label>Bezeichnung</label>
                        <textarea></textarea>
                    </div>
                    <div className='records-alignment'>
                        <span>Aufzeichnungen</span>
                    </div>
                    <div className='more-lessons'>
                        <button>
                            <img src={AddIcon} alt="AddIcon"/>
                            <span>Mehr Unterricht</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

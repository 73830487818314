import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./login.scss";
import classNames from "classnames";
import Auth from '../../../src/Helpers/auth';

function Logoutmodal() {
  const history = useHistory();

  const closeOnClick = () => {
    history.push(`/`);
  };

  const Logout = () => {
    history.push(`/Login`);
    Auth.Logout();
  };
  return (
    <>
      <div className="modalBg">
        <div className="modalcontent">
          <div className="modalbody">
            <div>
              <p className="logouttext">Jetzt abmelden?</p>
            </div>
            <div className="logutbtnSection">
              <button className="logutbtn" onClick={closeOnClick}>
              Abbrechen
              </button>

              <button onClick={Logout} className="logutbtn">
              Abmelden
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Logoutmodal;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./Notification.scss";
import classNames from "classnames";
import Auth from "../../../src/Helpers/auth";
import * as userUtil from "../../utils/user.util";
import { ApiGet, ApiDelete, ApiPut } from "../../Helpers/Api/ApiData";
import BellIcon from "../../bellicon.png";
import Loader from "react-loader-spinner";
import moment from "moment";

function Notification() {
  let userInfo = userUtil.getUserInfo();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [notiData, setNotiData] = useState();

  useEffect(() => {
    localStorage.setItem("CourseName", null);
    getAllNotification();
  }, []);

  const getAllNotification = async () => {
    setLoading(true);
    await ApiPut(`notification/view-notification?addedBy=${userInfo?._id}`)
      .then((res) => {
        setLoading(false);
        console.log("res", res);
        setNotiData(res?.data?.payload?.notification);
        console.log(res?.data?.payload?.notification);
      })
      .catch((err) => {
        console.log("err", err);
        // setLoading(false);
      });
  };

  return (
    <>
      <div>
        <div className="courses-section">
          <div className="page-title">
            <h1>Benachrichtigung</h1>
            <button className="btns" onClick={() => history.goBack()}>
          Zurück
          </button>
          </div>
          <Loader
            className="Loading"
            type="Puff"
            color="#5b5b5e"
            height={35}
            width={35}
            visible={loading}
          />

          <div className="grids">
            {/* <Loader
            className="Loading"
            type="Puff"
            color="#F29100"
            height={35}
            width={35}
            visible={loading}
          /> */}
            {notiData?.map((item, i) => {
              return (
                <>
                  <div className="grid-itemss">
                    {/* <div className="spce"> */}
                    <div>
                      <div className="flex-display">
                        <div>
                          <h2 className="lektion">{item?.title}</h2>
                        </div>
                        <div>
                          <span style={{ fontSize: "14px" }}>
                            {moment(item?.updatedAt).format("DD-MM-YYYY ")}
                          </span>
                        </div>
                      </div>

                      <div className="image-icon-center-alignment">
                        <div>
                          <img className="image" src={BellIcon} />
                        </div>
                        <div>
                          <span>{item?.description}</span>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </>
              );
            })}

            {!notiData?.length && loading === false ? (
              <>
                {" "}
                <div className="text-center">Keine Benachrichtigung.</div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Notification;

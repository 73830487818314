import { useEffect, useState } from "react";
import "./Progress.scss";
import "../../../src/routes/home/customerSupport//customer-support.scss";
import * as userUtil from "../../utils/user.util";
import { ApiGet } from "../../Helpers/Api/ApiData";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Progress from "react-progressbar";
import YearMonthPicker from "react-year-month-picker";
import _ from "lodash";

import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  LineChart,
  Line,
  ResponsiveContainer,
} from "recharts";

export default function UserProgress() {
  let userInfo = userUtil.getUserInfo();
  const [daywiseQuiz1, setDaywiseQuiz1] = useState();
  const [completeCourse1, setCompleteCourse1] = useState({});
  const [daywiseLesson1, setDaywiseLesson1] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedYM, setSelectedYM] = useState({ scheduled: null });
  const [selectedYM1, setSelectedYM1] = useState({ scheduled: null });
  const [selectedYM2, setSelectedYM2] = useState({ scheduled: null });

  let d = new Date();
  
  var cm = d.getMonth() + 1; //current month
  var cy = d.getFullYear(); //current year
  console.log("AAAAA", cm, cy);

  useEffect(() => {
    // getUserProgress();
    getCourseDoneProgress(cy, cm);
    getQuizProgress(cy, cm);
    getLessonProgress(cy, cm);
    myFunction();
    genDummyMonthArr();
    genDummyLessonArr();
    genDummyQuizArr();
  }, []);

  let now = new Date();
  const myFunction = () => {
    //for get total days o current month
    return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
  };

  let monARR = Array.apply(
    null,
    Array(new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate())
  ).map(function (x, i) {
    return i + 1;
  });

  const handleChange = (m) => {
    console.log("EEEEEEEE", m);
    // startOfMonth = m.startOf("month").format("YYYY-MM-DD");
    // endOfMonth = m.endOf("month").format("YYYY-MM-DD");
    // setSelectedYM({ scheduled: m });
    getCourseDoneProgress(m._a[0], m._a[1] + 1);
   
   
  };

  const handleChange1 = (m) => {
    console.log("EEEEEEEE", m);
    // startOfMonth = m.startOf("month").format("YYYY-MM-DD");
    // endOfMonth = m.endOf("month").format("YYYY-MM-DD");
    // setSelectedYM({ scheduled: m });
   
    getQuizProgress(m._a[0], m._a[1] + 1);
  };

  const handleChange2 = (m) => {
    console.log("EEEEEEEE", m);
    // startOfMonth = m.startOf("month").format("YYYY-MM-DD");
    // endOfMonth = m.endOf("month").format("YYYY-MM-DD");
    // setSelectedYM({ scheduled: m });
   
    getLessonProgress(m._a[0], m._a[1] + 1);
   
  };

  const mapCourseDone = (data) => {
    let monthCourseDoneData = [];

    Object.entries(data).map(([key, value]) => {
      return monthCourseDoneData.push({
        name: Number(key),
        completeCourse: value.length,
      });
    });

    // setCompleteCourse1(
    //   _.unionBy(_.unionBy(monthCourseDoneData, genDummyMonthArr(), "name"))
    // );
    setCompleteCourse1(
      _.orderBy(
        _.unionBy(monthCourseDoneData, genDummyMonthArr(), "name"),
        ["name"],
        ["asc"]
      )
    );
  };

  const mapLesson = (data) => {
    let monthLessonData = [];

    Object.entries(data).map(([key, value]) => {
      return monthLessonData.push({
        name: Number(key),
        Lektionen: value.length,
      });
    });

    // setDaywiseLesson1(_.unionBy(monthLessonData, genDummyLessonArr(), "name"));
    setDaywiseLesson1(
      _.orderBy(
        _.unionBy(monthLessonData, genDummyLessonArr(), "name"),
        ["name"],
        ["asc"]
      )
    );
  };

  const mapQuiz = (data) => {
    let monthQuizData = [];
    console.log("Daa", data);
    Object.entries(data).map(([key, value]) => {
      return monthQuizData.push({ name: Number(key), quiz: value.length });
    });

    setDaywiseQuiz1(
      _.orderBy(
        _.unionBy(monthQuizData, genDummyQuizArr(), "name"),
        ["name"],
        ["asc"]
      )
    );
  };

  const genDummyMonthArr = () => {
    let arr = [];
    [...Array(31)].map((_, index) =>
      arr.push({ name: Number(index + 1), completeCourse: 0 })
    );
    return arr;
  };
  const genDummyLessonArr = () => {
    let arr = [];
    [...Array(31)].map((_, index) =>
      arr.push({ name: Number(index + 1), Lektionen: 0 })
    );
    return arr;
  };
  const genDummyQuizArr = () => {
    let arr = [];
    [...Array(31)].map((_, index) =>
      arr.push({ name: Number(index + 1), quiz: 0 })
    );
    return arr;
  };

  const getCourseDoneProgress = async (year, month) => {
    //called when user select year & month
    await ApiGet(
      `progress/get-course-done-by-user?uid=${userInfo?._id}&year=${year}&month=${month}`
    )
      .then((res) => {
        console.log(
          "getCourseDoneProgress resss",
          res?.data?.payload?.courseDone
        );
        mapCourseDone(res?.data?.payload?.courseDone);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getLessonProgress = async (year, month) => {
    //called when user select year & month
    await ApiGet(
      `progress/get-lesson-by-user?uid=${userInfo?._id}&year=${year}&month=${month}`
    )
      .then((res) => {
        console.log("get lesson Progress resss", res?.data?.payload?.days);
        mapLesson(res?.data?.payload?.days);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getQuizProgress = async (year, month) => {
    //called when user select year & month
    await ApiGet(
      `progress/get-quiz-by-user?uid=${userInfo?._id}&day=true&year=${year}&month=${month}`
    )
      .then((res) => {
        console.log("getquiz Progress resss", res);
        mapQuiz(res?.data?.payload?.dayWiseQuiz);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // const getUserProgress = async () => {
  //   setLoading(true);

  //   await ApiGet(`progress/get-progress-dashboard?uid=${userInfo?._id}`)
  //     .then((res) => {
  //       setLoading(false);

  //       setProgresseData(res?.data?.payload);
  //       console.log("get progress", res?.data?.payload);
  //       let count = [];
  //       for (let i = 0; i < res?.data?.payload?.dayWiseCourse.length; i++) {
  //         res?.data?.payload?.dayWiseCourse[i].map((item) => {
  //           count.push(item?.course?.length);
  //           return item?.course?.length;
  //         });
  //       }
  //       setDaywiseCourse(count);
  //       setDaywiseQuiz(
  //         res?.data?.payload?.dayWiseQuiz?.map((item, index) => {
  //           return item?.length;
  //         })
  //       );
  //       setDaywiseQuiz(
  //         res?.data?.payload?.dayWiseQuiz?.map((item, index) => {
  //           return item?.length;
  //         })
  //       );
  //       setCompleteCourse(
  //         res?.data?.payload?.courseDone?.map((item, index) => {
  //           return item?.length;
  //         })
  //       );
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //       setLoading(false);
  //     });
  // };

  return (
    <>
      <div className="courses-section">
        <div className="page-title2">
          <h1> Mein Fortschritt</h1>
        </div>
        <Loader
          className="Loading"
          type="Puff"
          color="#5B5B5E"
          height={35}
          width={35}
          visible={loading}
        />
        {loading == false && (
          <div className="grids">
            <div className="grid-itemss grid-itemss2">
              <div className="spce">Abgeschlossene Module</div>
              {/* Complete Course */}
              <div className="cartSection">
                <YearMonthPicker
                  // defaultYear={cy}
                  // defaultMonth={moment(cd).format("MM")}
                  defaultYear={cy}
                  defaultMonth={cm}
                  minYear={2022}
                  maxYear={2050}
                  closeOnSelect
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                <LineChart width={1000} height={300} data={completeCourse1}>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                  <Line
                    type="monotone"
                    dataKey="completeCourse"
                    stroke="#5B5B5E"
                  />
                  <Line type="monotone" dataKey="pv" stroke="#82ca9d" />
                </LineChart>

                {/* <LineChart data={month} margin={{ right: 300 }}>
                <CartesianGrid />
                <XAxis dataKey="name" />
                <YAxis></YAxis>
                <Legend />
                <Tooltip />
                <Line
                  dataKey="completeCourse"
                  stroke="black"
                  activeDot={{ r: 8 }}
                />
                <Line dataKey="fees" stroke="red" activeDot={{ r: 8 }} />
              </LineChart> */}
                <h5 className="cartLeft">abgeschlossene Module</h5>
                {/* no of complete course  */}
                <h5 className="cartBottom">Tage/Monat</h5>
                {/* days/month   */}
              </div>
            </div>
            
            <div className="grap">
              <div className="grid-itemss">
                <div className="spce">Fortschritt Lektionen</div>
                {/* Day wise Progress  */}
                <div className="cartSection">
                  <YearMonthPicker
                    // defaultYear={cy}
                    // defaultMonth={moment(cd).format("MM")}
                    defaultYear={cy}
                    defaultMonth={cm}
                    minYear={2022}
                    maxYear={2050}
                    closeOnSelect
                    onChange={(e) => {
                      handleChange2(e);
                    }}
                  />
                  <BarChart width={1000} height={300} data={daywiseLesson1}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Lektionen" fill="#5B5B5E" barSize={20} />
                  </BarChart>
                  <h5 className="cartLeft">bearbeitete Lektionen</h5>
                  {/* no of Lektionen  */}
                  <h5 className="cartBottom">Tage/Woche</h5>
                </div>
              </div>

              <div className="grid-itemss">
                <div className="spce">Quiz</div>
                {/* Quiz Progress */}
                <div className="cartSection">
                  <YearMonthPicker
                    // defaultYear={cy}
                    // defaultMonth={moment(cd).format("MM")}
                    defaultYear={cy}
                    defaultMonth={cm}
                    minYear={2022}
                    maxYear={2050}
                    closeOnSelect
                    onChange={(e) => {
                      handleChange1(e);
                    }}
                  />
                  <BarChart width={1000} height={300} data={daywiseQuiz1}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="quiz" fill="#5B5B5E" barSize={20} />
                  </BarChart>
                  <h5 className="cartLeft">Anzahl Quiz</h5>
                  {/* no of quiz */}
                  <h5 className="cartBottom">Tage/Woche</h5>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

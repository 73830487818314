import { useState, useEffect, useRef } from "react";
import "./login.scss";
import { ApiPostNoAuth } from "../../Helpers/Api/ApiData";
import * as authUtil from "../../utils/auth.util";
import * as userUtil from "../../utils/user.util";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router";
import MicrosoftLogin from "react-microsoft-login";
import { MICROSOFT_KEY } from "../../Helpers/constants";
import logo from "../../components/sidebar/img/logo4.svg";

export default function Login() {
  const history = useHistory();
  const [loginData, setLoginData] = useState({});
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const regexEmail =
    /^(([^<>()[\],;:\s@]+([^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+)+[^<>()[\],;:\s@]{2,})$/i;

  const handleChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();

    if (!loginData.email && !loginData.password) {
      setErrors({
        email: " E-Mail muss erforderlich sein*",
        password: "Passwort muss erforderlich sein*",
      });
    } else if (!loginData.email || loginData.email === "") {
      setErrors({ ...errors, email: " E-Mail muss erforderlich sein*" });
    } else if (!loginData.email || regexEmail.test(loginData.email) === false) {
      setErrors({ ...errors, email: "  Email ist ungültig*" });
    } else if (!loginData.password || loginData.password === "") {
      setErrors({ ...errors, password: "Passwort muss erforderlich sein*" });
    } else {
      loginData.email = loginData.email.toLowerCase();
      await ApiPostNoAuth(`admin/login`, loginData)
        .then((res) => {
          console.log("login res", res?.dat?.payload?.admin?.position);
          if (res?.data?.result === 0) {             
             if(res?.data?.payload?.admin?.position==="User"){
              authUtil.setToken(res?.data?.payload?.token);
              userUtil.setUserInfo(res?.data?.payload?.admin);
              history.push("/");
              setTimeout(function () {
                toast.success("LogIn erfolgreich",{theme:"colored"});
              }, 300);
              setLoader(false);
             }
             else{
              toast.error("Konnte nicht einloggen.")
             }            
          } else {
            toast.error(res?.data?.message, { theme: "colored" });
            setLoader(false);
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log("err---->", err);
          toast.error(err, { theme: "colored" });
          sessionStorage.clear();
        });
    }
    setLoader(false);
  };

  const loginWithMicrosoftHandler = (err, data) => {
    if (err) return;
    setLoader(true);
    const body = {
      email: data.account.userName,
      isSocial: true,
    };
    ApiPostNoAuth(`admin/login`, body)
      .then((res) => {
        setLoader(true);
        if (res?.data?.result === 0) {
          window.location.reload();
          authUtil.setToken(res?.data?.payload?.token);
          userUtil.setUserInfo(res?.data?.payload?.admin);
          history.push("/");
          toast.success("Sie haben sich erfolgreich angemeldet", {
            theme: "colored",
          });
          setLoader(false);
        } else {
          toast.error(res?.data?.message, { theme: "colored" });
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log("err---->", err);
        toast.error(err, { theme: "colored" });
        setLoader(false);
        sessionStorage.clear();
      });
  };

  function useKey(key, cb) {
    const callback = useRef(cb);

    useEffect(() => {
      callback.current = cb;
    });

    useEffect(() => {
      function handle(event) {
        if (event.code === key) {
          callback.current(event);
        }
      }

      document.addEventListener("keypress", handle);
      return () => document.removeEventListener("keypress", handle);
    }, [key]);
  }
  useKey("Enter", handleSubmit);

  return (
    <div>
      <div className="login-page-center-alignment">
      <div className="logo-wrapper"><img   height="150px" width="150px "className="logo" src={logo}/> </div>  
        <div className="login-box">
          <div className="input">
            <label> E-Mail</label>
            <input
              autoComplete="off"
              type="email"
              name="email"
              placeholder="E-mail "
              value={
                loginData.email?.toLowerCase() && loginData.email?.toLowerCase()
              }
              onChange={(e) => {
                handleChange(e);
              }}
              onKeyDown={(e) => e.key == "Enter" && handleSubmit(e)}
            />
            <span className="text-danger">{errors.email}</span>
          </div>
          <div className="input">
            <label>Passwort</label>
            <input
              type="password"
              name="password"
              placeholder="●●●●●●●"
              value={loginData.password && loginData.password}
              onChange={(e) => handleChange(e)}
              onKeyDown={(e) => e.key == "Enter" && handleSubmit(e)}
            />
            <span className="text-danger">{errors.password}</span>
          </div>

          <div className="login-button-center">
            <button onKeyPress={handleSubmit}  style={{backgroundColor:"#DFD1B6"}} onClick={handleSubmit}>
              {loader ? (
                <>
                  <div>Wird geladen...</div>
                </>
              ) : (
                <>
                  <div>Anmelden</div>
                </>
              )}
            </button>
          </div>

          {/* <div className="box-bottom-text">
            <p>Passwort vergessen?</p>
          </div> */}

          <div className="box-text">
            <MicrosoftLogin
              clientId={MICROSOFT_KEY}
              authCallback={loginWithMicrosoftHandler}
              redirectUri="http://localhost:3000"
            />
            {/* <p>
              <i class="fab fa-microsoft"></i> Login With Microsoft
              
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { getToken, createMeeting } from "../Videosdk/api";
import { ApiGet, ApiPost } from "../../Helpers/Api/ApiData";
import "./Progress.scss";
import { ToastContainer, toast } from "react-toastify";
// import { Button, Modal } from "react-bootstrap";
import { getUserInfo } from "../../utils/user.util";
import { userInfo } from "os";
import DataTable, { defaultThemes } from "react-data-table-component";
import moment from "moment";
import Select from "react-select";
import Loader from "react-loader-spinner";
import { deburr } from "lodash";

const Meeting = () => {
  const [meetingLink, setMeetingLink] = useState();
  const [hostToken, setHostToken] = useState();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [users, setUsers] = useState();
  const [names, setNames] = useState();
  const [selectedNames, setSelectedNames] = useState();
  let userInfo = getUserInfo();

  useEffect(() => {
    getAllUsers();

    getNames();
  }, []);
  const getAllUsers = async () => {
    setLoading(true);
    // if(!search){
    await ApiGet(`video/get-list-by-user?email=${userInfo?.email}`)
      .then((res) => {
        setLoading(false);
        console.log("get courses", res?.data?.payload?.findMail);
        setUsers(res?.data?.payload?.findMail);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };

  const [state, setState] = useState({
    items: [],
    value: "",
    error: null,
  });
  const handleJoinHostSession = () => {
    window.open(
      `//incandescent-biscuit-25335f.netlify.app/?meetingId=${meetingLink}&token=${hostToken}&joinScreenEnabled=true&canDrawOnWhiteboard=true&canToggleWhiteboard=true`
    );
  };

  const handleCreateSession = async () => {
    const token = await getToken();
    const meetingId = await createMeeting({ token });
    setHostToken(token);
    setMeetingLink(meetingId);
    // window.open(
    //   `//copper-legitimate.buildx.live/?meetingId=${meetingId}&token=${token}&joinScreenEnabled=true`
    // ); 
  };

  const sendPeperSet = () => {
    const data = {
      email: selectedNames.map((item) => item.label),
      meetingId: `incandescent-biscuit-25335f.netlify.app/?meetingId=${meetingLink}&joinScreenEnabled=true`,
      aid: userInfo?._id,
    };

    ApiPost(`video/send-meetingId`, data)
      .then((res) => {
        if (res?.status == 200) {
          toast.success("Einladung an die Mail gesendet");
          getAllUsers();
          console.log("log", res?.data?.message);
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const getNames = async () => {
    setLoading(true);
    // if(!search){
    await ApiGet(`admin/getUserListForBoard?&aid=${userInfo?.aid}`)
      .then((res) => {
        setLoading(false);
        handleUserNames(res?.data?.payload?.findUsers);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const handleUserNames = (data) => {
    let tempUserName = [];
    data.map((item) =>
      tempUserName.push({ value: item._id, label: item.email })
    );
    setNames(tempUserName);
  };
  const handleRejoinButton = async (meetingId) => {
    const token = await getToken();
    window.open(`//${meetingId}&token=${token}`);
  };

  const columnProvider = () => {
    return [
      {
        name: "SNo",
        cell: (row, index) => index + 1,
        width: "65px",
      },

      {
        name: "Datum",
        cell: (row, index) => moment(row?.updatedAt).format("lll "),
        sortable: true,
      },

      {
        name: "E-Mail",
        selector: "email",
        sortable: true,
      },
      {
        name: "Meeting ID",
        cell: (row) => {
          return (
            <>
              <button
                class="btns"
                onClick={() => handleRejoinButton(row?.meetingId)}
              >
                Teilnehmen
              </button>
            </>
          );
        },
        // selector: "meetingId",
        wrap: true,
        sortable: true,
      },
    ];
  };
  const columns = columnProvider();

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  return (
    <div>
      <ToastContainer />
      <div className="courses-section">
        <div className="page-title2">
          <h1> Konferenz</h1>
        </div>
        <button
          class="btns"
          onClick={() => {
            setModal(true);
            handleCreateSession();
          }}
        >
          Neue Videokonferenz
        </button>

        {modal === true && (
          <div className="modalSection">
            <div className="modal-dialog">
              <div className="modal">
                <div className="modalHeader">
                  <div className="modal-title">
                    Einladung via E-Mail versenden
                  </div>
                </div>

                <div className="modal-body">
                  Wählen Sie Nutzer für das Live-Meeting
                  {/* <input type="text" className="formInput"/> */}
                  <Select
                    className="formInput"
                    // className="form-control"
                    options={names}
                    // placeholder="Type or paste email addresses and press `Enter`..."
                    // onKeyDown={(e) => handleKeyDown(e)}
                    onChange={setSelectedNames}
                    isMulti
                    required
                    // onPaste={(e) => handlePaste(e)}
                  />
                  <div className="py-2">
                    {state.error && <p className="error">{state.error}</p>}
                  </div>
                  <br />
                  <div className="py-2">
                    {selectedNames?.length > 0 ? (
                      <>
                        <button
                          type="submit"
                          class="btns"
                          onClick={() => sendPeperSet()}
                        >
                          Einladung senden
                        </button>
                      </>
                    ) : (
                      ""
                    )}
                    <br />
                  </div>
                  <div className="modal-footer">
                    <button class="btns" onClick={handleJoinHostSession}>
                      Teilnehmen
                    </button>

                    <button
                      class="btns"
                      onClick={() => {
                        setModal(false);
                        setState({
                          items: [],
                          value: "",
                          error: null,
                        });
                      }}
                    >
                      Abbrechen
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Loader
        className="Loading"
        type="Puff"
        color="#5B5B5E"
        height={35}
        width={35}
        visible={loading}
      />

      {loading === false && users?.length > 0 && (
        <div style={{ background: "red" }}>
          <DataTable
            columns={columns}
            data={users}
            customStyles={customStyles}
          />
        </div>
      )}
      {/* no data found */}
      {loading === false && users?.length === 0 && (
        <div style={{ minHeight: "80px", display:"flex", justifyContent:"center", alignItems:'center', background: "#fff" }}>Keine Daten vorhanden</div>
      )}
    </div>
  );
};

export default Meeting;

import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './styles/mixins/global.scss';
// import "../node_modules/video-react/dist/video-react.css"; 
import Routes from './routes';
import { registerLicense } from '@syncfusion/ej2-base';
import { ToastContainer } from "react-toastify";
// registerLicense('ORg4AjUWIQA/Gnt2VVhhQlFac1pJWnxIf0x0RWFbb1h6d1JMZVVBNQtUQF1hS35Vd0RjX3xWc3NdRmdf');
registerLicense('ORg4AjUWIQA/Gnt2VVhiQlFadVlJVHxKYVF2R2FJeFR1dV9FZ0wgOX1dQl9hSXZTckVkXHpecnBXQ2A=');
function App() {
  return (
    <>
      <ToastContainer />
      <Routes />
    </>
  );
}

export default App;

import { useEffect, useState } from "react";
import "./customer-support.scss";
import "../../home/home.scss";
import { ApiGet, ApiPut } from "../../../Helpers/Api/ApiData";
import * as userUtil from "../../../utils/user.util";
import CardImage from "../../../assets/images/card-image.png";
import RatingIcon from "../../../assets/icons/rating.svg";
import CoursesIcon from "../../../assets/icons/courses.svg";
import Slider from "react-slick";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import SearchIcon from "../../../assets/icons/search.svg";
import notifications from "../../../bell.png"

export default function CustomerSupport() {
  const [loading, setLoading] = useState(true);
  const [filteredReport, setFilteredReport] = useState([]);
  const [count, setCount] = useState();

  const getNotification=()=>{
    history.push(`/Notification`);
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  };
  const getCount = async () => {
    setLoading(true);
    await ApiGet(`notification/get-count-notification?addedBy=${userInfo?._id}`)
      .then((res) => {
        setLoading(false);
        setCount(res?.data?.payload?.count);
      
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const addCount = async () => {
    setLoading(true);
    await ApiPut(`notification/view-notification?addedBy=${userInfo?._id}`)
      .then((res) => {
        setLoading(false);
        // setCount(res?.data?.payload?.count);
      history.push('/Notification')
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  let userInfo = userUtil.getUserInfo();
  const history = useHistory();
  const [courseData, setCourseData] = useState([]);

  useEffect(() => {
    getAllCourses();
    getCount();
  }, []);

  const getAllCourses = async () => {
    setLoading(true);
    await ApiGet(`course/get-all-course-by-category?aid=${userInfo?.aid}`)
      .then((res) => {
        setLoading(false);
        setCourseData(res?.data?.payload?.courses);
        console.log("course data on home page", res?.data?.payload?.courses);
        setFilteredReport(res?.data?.payload?.courses);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  console.log("courseData", courseData);
  const handleSearch = (e) => {
    var value = e.target.value.toLowerCase();

    if (value === "") {
      setCourseData(filteredReport);
    } else {
      let filterData = filteredReport?.map((report) => {
        let course = report?.courses.filter((x) =>
          x?.name.toLowerCase().includes(e.target.value)
        );
        if (course?.length > 0) {
          return {
            ...report,
            courses: course,
          };
        }
      });
      setCourseData(filterData);
    }
  };
  return (
    <div>
      <div className="customer-support-section">
        <div className="home-section-alignment">
        <div className="search-input">
            <input
              type="text"
              placeholder="Suchen ..."
              onChange={(e) => handleSearch(e)}
            />
            <div className="search-icon-alignment">
              <img src={SearchIcon} alt="SearchIcon" />
            </div>
          </div>
          <div className="bellIcon"  onClick={() => {
                  // getNotification()
                  addCount()
                  }}><div className="badge">{count}</div><img src={notifications}/></div>
        </div>
        <Loader
          className="Loading"
          type="Puff"
          color="#5B5B5E"
          height={35}
          width={35}
          visible={loading}
        />
        {courseData?.length === 0 && loading === false ? (
          <>
            <div className="textt">Keine Daten vorhanden.</div>
          </>
        ) : (
          <>
            {courseData?.map((item, index) => {
              return (
                <>
                  {item?.courses?.length > 0 && (
                    <div className="page-title">
                      <h1>{item?.name}</h1>
                    </div>
                  )}
                  <div className="grid">
                    {item?.courses?.map((item1, index) => {
                      return (
                        <><div className="grid-items">
                          <div className="slider-box-design">
                            <Slider {...settings}>
                              
                                <div className="card-image">
                                  <img src={item1?.image} alt="CardImage" />
                                </div>
                                <div className="card-details">
                                  <h2>{item1?.name}</h2>
                                  <div className="icon-text-alignment">
                                    <div className="lesson">
                                      {/* <p>11 lessons</p> */}
                                      {/* <div className="rating-alignment">
                                        <img src={RatingIcon} />
                                        <span>4.5</span>
                                      </div> */}
                                    </div>
                                    <div
                                      className="icon-design"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "CourseName",
                                          item1?.name
                                        );
                                        history.push(
                                          `/Courses-details/${item1?._id}`
                                        );
                                      }}
                                    >
                                      <img
                                        src={CoursesIcon}
                                        alt="CoursesIcon"
                                      />
                                    </div>
                                  </div>
                                </div>
                              
                            </Slider>
                          </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </>
              );
            })}
          </>
        )}

        {/* <div className="slider-box-design">
          <Slider {...settings}>
            <div className="grid-items">
              <div className="card-image">
                <img src={CardImage} alt="CardImage" />
              </div>
              <div className="card-details">
                <h2>Sympathy, Courtesy, presentation</h2>
                <div className="icon-text-alignment">
                  <div className="lesson">
                    <p>11 lessons</p>
                    <div className="rating-alignment">
                      <img src={RatingIcon} />
                      <span>4.5</span>
                    </div>
                  </div>
                  <div className="icon-design">
                    <img src={CoursesIcon} alt="CoursesIcon" />
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div> */}
      </div>
    </div>
  );
}
